import { request, noTimeOutReq } from '@/utils/request.js'

// 【选考场】
// 获取配置地区及其所有子地区
export function getArea () {
  return request({
    url: '/exam/score/mark/scene/area/get',
    method: 'post',
  })
}
// 根据地区id获取考点
export function getPlace (sysOrgAreaId) {
  return request({
    url: `/exam/score/mark/scene/place/${sysOrgAreaId}/list`,
    method: 'post',
  })
}
// 根据考点id获取考场
export function getAreaRoom (sysOrgExamPlaceId) {
  return request({
    url: `/exam/score/mark/scene/place/room/${sysOrgExamPlaceId}/list`,
    method: 'post',
  })
}
// 根据考场id获取教师参与的现场评分考试列表
export function getExamInfo (sysOrgExamPlaceRoomId) {
  return request({
    url: `/exam/score/mark/scene/examInfo/${sysOrgExamPlaceRoomId}/list`,
    method: 'post',
  })
}

// 【选座位】
// 获取考试信息
export function sceneExamInfo (examId, sysOrgExamPlaceRoomId, sysOrgExamPlaceId) {
  return request({
    url: `/exam/score/mark/scene/examInfo/${examId}/${sysOrgExamPlaceRoomId}/${sysOrgExamPlaceId}/get`,
    method: 'post',
  })
}
// 获取考试信息
export function sceneSeat (examTimeId, examId, sysOrgExamPlaceRoomId) {
  return request({
    url: `/exam/score/mark/scene/seat/${examTimeId}/${examId}/${sysOrgExamPlaceRoomId}/get`,
    method: 'post',
  })
}
// 查询是否为阅卷组长
export function getLeaderTeacher (examId) {
  return request({
    url: `/exam/score/mark/scene/is/teacher/leader/${examId}`,
    method: 'post',
  })
}
// 提交选择的座位
export function saveSeat (data) {
  return request({
    url: `/exam/score/mark/scene/seat/chosen/save`,
    method: 'post',
    data
  })
}

// 【评分】
// 获取考试信息和座位题目
export function operationTest (examTimeId, examId, examPlaceId) {
  return request({
    url: `/exam/score/mark/scene/examInfo/operationTest/${examTimeId}/${examId}/${examPlaceId}/get`,
    method: 'post',
  })
}
// 获取签名信息
export function getSign (examId, examTimeId, examineeId) {
  return request({
    url: `/exam/score/mark/scene/${examId}/${examTimeId}/${examineeId}/signature/get`,
    method: 'post',
  })
}
// 签名提交
export function sendSign (data) {
  return request({
    url: `/exam/score/mark/scene/signature/save`,
    method: 'post',
    data
  })
}
// 锁定签名
export function lockSign (examId, examTimeId, examineeId) {
  return request({
    url: `/exam/score/mark/scene/${examId}/${examTimeId}/${examineeId}/signature/lock/save`,
    method: 'post'
  })
}
// 阅卷教师提交评分
export function sceneOperationTest (examTimeId, examId, data) {
  return request({
    url: `/exam/score/mark/scene/${examTimeId}/${examId}/score/save`,
    method: 'post',
    data
  })
}
// 阅卷自动提交评分
// export function autoSignature (examId, examTimeId, data) {
//   return request({
//     url: `/exam/score/mark/scene/${examId}/${examTimeId}/verify/signature/lock/save`,
//     method: 'post',
//     data
//   })
// }

// 【评分】 考试|场次|考生签名 状态
export function sceneData (examId, examTimeId, data) {
  return request({
    url: `/exam/score/mark/scene/${examId}/${examTimeId}/status/get`,
    method: 'post',
    data
  })
}

// 【评分】  随到随考判断下一场次开始
export function nextStart (examId, examTimeId) {
  return request({
    url: `/exam/score/mark/scene/${examId}/${examTimeId}/next/session/start`,
    method: 'post',
  })
}

// 【评分】【直播】 保存上一批次座位信息并返回考试时间Id
export function getNextExamTime (examId, examPlaceId, examTimeId) {
  return request({
    url: `/exam/score/mark/scene/${examId}/${examPlaceId}/${examTimeId}/examTime/get`,
    method: 'post',
  })
}

