<template>
  <div class="backgroundLoin">
    <div class="bgTop">
      <img src="@/assets/scoreViewsPad/PadScoreLogin.png" />
    </div>
    <el-card class="box-card">
      <div class="boxTit">选择考场</div>
      <el-form
        label-position="right"
        label-width="80px"
        ref="formLion"
        :model="formLion"
      >
        <el-form-item label="区域">
          <el-select
            v-model="formLion.sysOrgAreaId"
            placeholder="请选择区县"
            @change="getPlaceList(formLion.sysOrgAreaId)"
          >
            <el-option
              v-for="val in this.AreaData"
              :key="val.sysOrgAreaId"
              :label="val.name"
              :value="val.sysOrgAreaId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考点">
          <el-select
            v-model="formLion.sysOrgExamPlaceId"
            placeholder="请选择学校"
            @change="getAreaRoomList(formLion.sysOrgExamPlaceId)"
          >
            <el-option
              v-for="val in this.PlaceData"
              :key="val.sysOrgExamPlaceId"
              :label="val.name"
              :value="val.sysOrgExamPlaceId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考场名称">
          <el-select
            v-model="formLion.sysOrgExamPlaceRoomId"
            placeholder="请选择考场"
            @change="getExamInfoList(formLion.sysOrgExamPlaceRoomId)"
          >
            <el-option
              v-for="val in this.AreaRoomData"
              :key="val.sysOrgExamPlaceRoomId"
              :label="val.name"
              :value="val.sysOrgExamPlaceRoomId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称">
          <el-select v-model="formLion.examId" placeholder="请选择考试">
            <el-option
              v-for="val in this.ExamInfoData"
              :key="val.examId"
              :label="val.examName"
              :value="val.examId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button
          v-throttle
          type="primary"
          :loading="loadingBtn"
          @click="sureBtn"
          class="loginBtn"
          >确认</el-button
        >
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {
  getArea,
  getPlace,
  getAreaRoom,
  getExamInfo,
} from '@/api/PadRating/PadRating.js'
export default {
  name: 'chooseExamRoom',
  data() {
    return {
      AreaData: [],
      PlaceData: [],
      AreaRoomData: [],
      ExamInfoData: [],
      formLion: {
        sysOrgAreaId: '',
        sysOrgExamPlaceId: '',
        sysOrgExamPlaceRoomId: '',
        examId: '',
      },
      loadingBtn: false,
    }
  },
  watch: {},
  mounted() {
    this.getAreaList()
  },
  methods: {
    // 获取区域
    getAreaList() {
      getArea().then((res) => {
        console.log('获取区域', res)
        this.PlaceData = []
        this.AreaRoomData = []
        this.ExamInfoData = []
        this.AreaData = []
        if (res.success) {
          this.AreaData = res.data.childrenOrgArea
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 获取考点
    getPlaceList(id) {
      getPlace(id).then((res) => {
        console.log('获取考点', res)
        this.formLion.sysOrgExamPlaceId = ''
        this.formLion.sysOrgExamPlaceRoomId = ''
        this.formLion.examId = ''
        this.AreaRoomData = []
        this.ExamInfoData = []
        this.PlaceData = []
        if (res.success) {
          this.PlaceData = res.data
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 获取考场名称
    getAreaRoomList(id) {
      getAreaRoom(id).then((res) => {
        console.log('考场名称', res)
        this.formLion.sysOrgExamPlaceRoomId = ''
        this.formLion.examId = ''
        this.ExamInfoData = []
        this.AreaRoomData = []
        if (res.success) {
          this.AreaRoomData = res.data
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 获取考试名称
    getExamInfoList(id) {
      getExamInfo(id).then((res) => {
        console.log('考试名称', res)
        this.formLion.examId = ''
        this.ExamInfoData = []
        if (res.success) {
          this.ExamInfoData = res.data
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    sureBtn() {
      if (!this.formLion.examId) return this.$message.warning('缺少必选项')
      this.$router.push({
        path: '/scoreSelection',
        query: {
          examId: this.formLion.examId,
          sysOrgExamPlaceRoomId: this.formLion.sysOrgExamPlaceRoomId,
          sysOrgExamPlaceId: this.formLion.sysOrgExamPlaceId,
        },
      })
    },
  },
}
</script>
<style scoped>
@media screen and (min-width: 960px) {
  .bgTop {
    height: 350px;
    margin-bottom: 56px;
    background: url(../../assets/scoreViewsPad/PcScoreLoginBg.png) no-repeat;
  }
}
@media screen and (max-width: 960px) {
  .bgTop {
    height: 230px;
    margin-bottom: 40px;
    background: url(../../assets/scoreViewsPad/PadScoreLoginBg.png) no-repeat;
  }
}
.bgTop {
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.bgTop img {
  max-width: 100%;
  max-height: 100%;
}
.box-card {
  margin: 0 auto;
  width: 412px;
  /* height: 350px; */
}
.boxTit {
  letter-spacing: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 27px;
  height: 80px;
  line-height: 60px;
}
.loginBtn {
  background: #4cd8f9;
  border-color: #4cd8f9;
  box-shadow: 0px 2px 4px 1px #4cd9f975;
  padding: 10px 30px;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
}
::v-deep .el-select {
  width: 100%;
}
</style>
